import { default as aboutUsHFeEw16jb4Meta } from "/app/pages/aboutUs.vue?macro=true";
import { default as checkQueuedEmails8KJrtrid4xMeta } from "/app/pages/admin/checkQueuedEmails.vue?macro=true";
import { default as editYViNz8UcS9Meta } from "/app/pages/admin/guest/[id]/edit.vue?macro=true";
import { default as indexWbBB2EL1TaMeta } from "/app/pages/admin/guest/[id]/index.vue?macro=true";
import { default as guests7HHLv7464JMeta } from "/app/pages/admin/guests.vue?macro=true";
import { default as editmzMztyO9tIMeta } from "/app/pages/admin/host/[id]/edit.vue?macro=true";
import { default as index2zMmZkb7byMeta } from "/app/pages/admin/host/[id]/index.vue?macro=true";
import { default as hostsES2q7ni4tWMeta } from "/app/pages/admin/hosts.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as inform4p0ytTaaVAMeta } from "/app/pages/admin/inform.vue?macro=true";
import { default as _91id_93yBVDO49y0FMeta } from "/app/pages/admin/match/[id].vue?macro=true";
import { default as indexmFXg3d5PyKMeta } from "/app/pages/admin/match/index.vue?macro=true";
import { default as _91id_93JgSLCuzcFaMeta } from "/app/pages/admin/matching/[id].vue?macro=true";
import { default as indexVYpuH5WLo2Meta } from "/app/pages/admin/matching/index.vue?macro=true";
import { default as matchyMHdYDj7ZMMeta } from "/app/pages/admin/matching/match.vue?macro=true";
import { default as migrateXpOvjGHxCdMeta } from "/app/pages/admin/migrate.vue?macro=true";
import { default as notAdminAccount9iW01cutCUMeta } from "/app/pages/admin/notAdminAccount.vue?macro=true";
import { default as notApprovedAdminPZVXysECQPMeta } from "/app/pages/admin/notApprovedAdmin.vue?macro=true";
import { default as queueEmailGuestsiUURPyD5TVMeta } from "/app/pages/admin/queueEmailGuests.vue?macro=true";
import { default as queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta } from "/app/pages/admin/queueEmailHostLastYearNotRegisteredAgain.vue?macro=true";
import { default as queueEmailMatchedGuestsSeZ5AFi054Meta } from "/app/pages/admin/queueEmailMatchedGuests.vue?macro=true";
import { default as queueEmailMatchedHostsfSglX2Ci4lMeta } from "/app/pages/admin/queueEmailMatchedHosts.vue?macro=true";
import { default as registerJ9n6lFFJhbMeta } from "/app/pages/admin/register.vue?macro=true";
import { default as resendMatchEmailsKd4CMkJ0Q0Meta } from "/app/pages/admin/resendMatchEmails.vue?macro=true";
import { default as roomsWz58ChAzt5Meta } from "/app/pages/admin/rooms.vue?macro=true";
import { default as confirmNewsletterTrqX2OYym4Meta } from "/app/pages/confirmNewsletter.vue?macro=true";
import { default as contactUssMQ6gX1U2qMeta } from "/app/pages/contactUs.vue?macro=true";
import { default as dsgvoze1kwAX27hMeta } from "/app/pages/dsgvo.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as HowItWorksIIHwyNaBEHMeta } from "/app/pages/HowItWorks.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as otp63Htgd7JyaMeta } from "/app/pages/otp.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as resendConfirmationEmailZkezVO4IOHMeta } from "/app/pages/resendConfirmationEmail.vue?macro=true";
import { default as confirmEmaildu4BEQuqJYMeta } from "/app/pages/signUp/guest/confirmEmail.vue?macro=true";
import { default as formbrygspJltbMeta } from "/app/pages/signUp/guest/form.vue?macro=true";
import { default as getStartedhEJkV56DFFMeta } from "/app/pages/signUp/guest/getStarted.vue?macro=true";
import { default as indexffJDdXbRL3Meta } from "/app/pages/signUp/guest/index.vue?macro=true";
import { default as successq1juMKpFngMeta } from "/app/pages/signUp/guest/success.vue?macro=true";
import { default as confirmEmailAJpaAgM21jMeta } from "/app/pages/signUp/host/confirmEmail.vue?macro=true";
import { default as formQJAOclckxYMeta } from "/app/pages/signUp/host/form.vue?macro=true";
import { default as getStarted4XwZtGx5S1Meta } from "/app/pages/signUp/host/getStarted.vue?macro=true";
import { default as index7CM3ILHpOJMeta } from "/app/pages/signUp/host/index.vue?macro=true";
import { default as success62Dy1UKI0RMeta } from "/app/pages/signUp/host/success.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
export default [
  {
    name: aboutUsHFeEw16jb4Meta?.name ?? "aboutUs___en",
    path: aboutUsHFeEw16jb4Meta?.path ?? "/aboutUs",
    meta: aboutUsHFeEw16jb4Meta || {},
    alias: aboutUsHFeEw16jb4Meta?.alias || [],
    redirect: aboutUsHFeEw16jb4Meta?.redirect,
    component: () => import("/app/pages/aboutUs.vue").then(m => m.default || m)
  },
  {
    name: aboutUsHFeEw16jb4Meta?.name ?? "aboutUs___de",
    path: aboutUsHFeEw16jb4Meta?.path ?? "/de/aboutUs",
    meta: aboutUsHFeEw16jb4Meta || {},
    alias: aboutUsHFeEw16jb4Meta?.alias || [],
    redirect: aboutUsHFeEw16jb4Meta?.redirect,
    component: () => import("/app/pages/aboutUs.vue").then(m => m.default || m)
  },
  {
    name: checkQueuedEmails8KJrtrid4xMeta?.name ?? "admin-checkQueuedEmails___en",
    path: checkQueuedEmails8KJrtrid4xMeta?.path ?? "/admin/checkQueuedEmails",
    meta: checkQueuedEmails8KJrtrid4xMeta || {},
    alias: checkQueuedEmails8KJrtrid4xMeta?.alias || [],
    redirect: checkQueuedEmails8KJrtrid4xMeta?.redirect,
    component: () => import("/app/pages/admin/checkQueuedEmails.vue").then(m => m.default || m)
  },
  {
    name: checkQueuedEmails8KJrtrid4xMeta?.name ?? "admin-checkQueuedEmails___de",
    path: checkQueuedEmails8KJrtrid4xMeta?.path ?? "/de/admin/checkQueuedEmails",
    meta: checkQueuedEmails8KJrtrid4xMeta || {},
    alias: checkQueuedEmails8KJrtrid4xMeta?.alias || [],
    redirect: checkQueuedEmails8KJrtrid4xMeta?.redirect,
    component: () => import("/app/pages/admin/checkQueuedEmails.vue").then(m => m.default || m)
  },
  {
    name: editYViNz8UcS9Meta?.name ?? "admin-guest-id-edit___en",
    path: editYViNz8UcS9Meta?.path ?? "/admin/guest/:id()/edit",
    meta: editYViNz8UcS9Meta || {},
    alias: editYViNz8UcS9Meta?.alias || [],
    redirect: editYViNz8UcS9Meta?.redirect,
    component: () => import("/app/pages/admin/guest/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editYViNz8UcS9Meta?.name ?? "admin-guest-id-edit___de",
    path: editYViNz8UcS9Meta?.path ?? "/de/admin/guest/:id()/edit",
    meta: editYViNz8UcS9Meta || {},
    alias: editYViNz8UcS9Meta?.alias || [],
    redirect: editYViNz8UcS9Meta?.redirect,
    component: () => import("/app/pages/admin/guest/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexWbBB2EL1TaMeta?.name ?? "admin-guest-id___en",
    path: indexWbBB2EL1TaMeta?.path ?? "/admin/guest/:id()",
    meta: indexWbBB2EL1TaMeta || {},
    alias: indexWbBB2EL1TaMeta?.alias || [],
    redirect: indexWbBB2EL1TaMeta?.redirect,
    component: () => import("/app/pages/admin/guest/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWbBB2EL1TaMeta?.name ?? "admin-guest-id___de",
    path: indexWbBB2EL1TaMeta?.path ?? "/de/admin/guest/:id()",
    meta: indexWbBB2EL1TaMeta || {},
    alias: indexWbBB2EL1TaMeta?.alias || [],
    redirect: indexWbBB2EL1TaMeta?.redirect,
    component: () => import("/app/pages/admin/guest/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: guests7HHLv7464JMeta?.name ?? "admin-guests___en",
    path: guests7HHLv7464JMeta?.path ?? "/admin/guests",
    meta: guests7HHLv7464JMeta || {},
    alias: guests7HHLv7464JMeta?.alias || [],
    redirect: guests7HHLv7464JMeta?.redirect,
    component: () => import("/app/pages/admin/guests.vue").then(m => m.default || m)
  },
  {
    name: guests7HHLv7464JMeta?.name ?? "admin-guests___de",
    path: guests7HHLv7464JMeta?.path ?? "/de/admin/guests",
    meta: guests7HHLv7464JMeta || {},
    alias: guests7HHLv7464JMeta?.alias || [],
    redirect: guests7HHLv7464JMeta?.redirect,
    component: () => import("/app/pages/admin/guests.vue").then(m => m.default || m)
  },
  {
    name: editmzMztyO9tIMeta?.name ?? "admin-host-id-edit___en",
    path: editmzMztyO9tIMeta?.path ?? "/admin/host/:id()/edit",
    meta: editmzMztyO9tIMeta || {},
    alias: editmzMztyO9tIMeta?.alias || [],
    redirect: editmzMztyO9tIMeta?.redirect,
    component: () => import("/app/pages/admin/host/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editmzMztyO9tIMeta?.name ?? "admin-host-id-edit___de",
    path: editmzMztyO9tIMeta?.path ?? "/de/admin/host/:id()/edit",
    meta: editmzMztyO9tIMeta || {},
    alias: editmzMztyO9tIMeta?.alias || [],
    redirect: editmzMztyO9tIMeta?.redirect,
    component: () => import("/app/pages/admin/host/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index2zMmZkb7byMeta?.name ?? "admin-host-id___en",
    path: index2zMmZkb7byMeta?.path ?? "/admin/host/:id()",
    meta: index2zMmZkb7byMeta || {},
    alias: index2zMmZkb7byMeta?.alias || [],
    redirect: index2zMmZkb7byMeta?.redirect,
    component: () => import("/app/pages/admin/host/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2zMmZkb7byMeta?.name ?? "admin-host-id___de",
    path: index2zMmZkb7byMeta?.path ?? "/de/admin/host/:id()",
    meta: index2zMmZkb7byMeta || {},
    alias: index2zMmZkb7byMeta?.alias || [],
    redirect: index2zMmZkb7byMeta?.redirect,
    component: () => import("/app/pages/admin/host/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: hostsES2q7ni4tWMeta?.name ?? "admin-hosts___en",
    path: hostsES2q7ni4tWMeta?.path ?? "/admin/hosts",
    meta: hostsES2q7ni4tWMeta || {},
    alias: hostsES2q7ni4tWMeta?.alias || [],
    redirect: hostsES2q7ni4tWMeta?.redirect,
    component: () => import("/app/pages/admin/hosts.vue").then(m => m.default || m)
  },
  {
    name: hostsES2q7ni4tWMeta?.name ?? "admin-hosts___de",
    path: hostsES2q7ni4tWMeta?.path ?? "/de/admin/hosts",
    meta: hostsES2q7ni4tWMeta || {},
    alias: hostsES2q7ni4tWMeta?.alias || [],
    redirect: hostsES2q7ni4tWMeta?.redirect,
    component: () => import("/app/pages/admin/hosts.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin___en",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin___de",
    path: indexi8nCATZISJMeta?.path ?? "/de/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: inform4p0ytTaaVAMeta?.name ?? "admin-inform___en",
    path: inform4p0ytTaaVAMeta?.path ?? "/admin/inform",
    meta: inform4p0ytTaaVAMeta || {},
    alias: inform4p0ytTaaVAMeta?.alias || [],
    redirect: inform4p0ytTaaVAMeta?.redirect,
    component: () => import("/app/pages/admin/inform.vue").then(m => m.default || m)
  },
  {
    name: inform4p0ytTaaVAMeta?.name ?? "admin-inform___de",
    path: inform4p0ytTaaVAMeta?.path ?? "/de/admin/inform",
    meta: inform4p0ytTaaVAMeta || {},
    alias: inform4p0ytTaaVAMeta?.alias || [],
    redirect: inform4p0ytTaaVAMeta?.redirect,
    component: () => import("/app/pages/admin/inform.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yBVDO49y0FMeta?.name ?? "admin-match-id___en",
    path: _91id_93yBVDO49y0FMeta?.path ?? "/admin/match/:id()",
    meta: _91id_93yBVDO49y0FMeta || {},
    alias: _91id_93yBVDO49y0FMeta?.alias || [],
    redirect: _91id_93yBVDO49y0FMeta?.redirect,
    component: () => import("/app/pages/admin/match/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yBVDO49y0FMeta?.name ?? "admin-match-id___de",
    path: _91id_93yBVDO49y0FMeta?.path ?? "/de/admin/match/:id()",
    meta: _91id_93yBVDO49y0FMeta || {},
    alias: _91id_93yBVDO49y0FMeta?.alias || [],
    redirect: _91id_93yBVDO49y0FMeta?.redirect,
    component: () => import("/app/pages/admin/match/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmFXg3d5PyKMeta?.name ?? "admin-match___en",
    path: indexmFXg3d5PyKMeta?.path ?? "/admin/match",
    meta: indexmFXg3d5PyKMeta || {},
    alias: indexmFXg3d5PyKMeta?.alias || [],
    redirect: indexmFXg3d5PyKMeta?.redirect,
    component: () => import("/app/pages/admin/match/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFXg3d5PyKMeta?.name ?? "admin-match___de",
    path: indexmFXg3d5PyKMeta?.path ?? "/de/admin/match",
    meta: indexmFXg3d5PyKMeta || {},
    alias: indexmFXg3d5PyKMeta?.alias || [],
    redirect: indexmFXg3d5PyKMeta?.redirect,
    component: () => import("/app/pages/admin/match/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JgSLCuzcFaMeta?.name ?? "admin-matching-id___en",
    path: _91id_93JgSLCuzcFaMeta?.path ?? "/admin/matching/:id()",
    meta: _91id_93JgSLCuzcFaMeta || {},
    alias: _91id_93JgSLCuzcFaMeta?.alias || [],
    redirect: _91id_93JgSLCuzcFaMeta?.redirect,
    component: () => import("/app/pages/admin/matching/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93JgSLCuzcFaMeta?.name ?? "admin-matching-id___de",
    path: _91id_93JgSLCuzcFaMeta?.path ?? "/de/admin/matching/:id()",
    meta: _91id_93JgSLCuzcFaMeta || {},
    alias: _91id_93JgSLCuzcFaMeta?.alias || [],
    redirect: _91id_93JgSLCuzcFaMeta?.redirect,
    component: () => import("/app/pages/admin/matching/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVYpuH5WLo2Meta?.name ?? "admin-matching___en",
    path: indexVYpuH5WLo2Meta?.path ?? "/admin/matching",
    meta: indexVYpuH5WLo2Meta || {},
    alias: indexVYpuH5WLo2Meta?.alias || [],
    redirect: indexVYpuH5WLo2Meta?.redirect,
    component: () => import("/app/pages/admin/matching/index.vue").then(m => m.default || m)
  },
  {
    name: indexVYpuH5WLo2Meta?.name ?? "admin-matching___de",
    path: indexVYpuH5WLo2Meta?.path ?? "/de/admin/matching",
    meta: indexVYpuH5WLo2Meta || {},
    alias: indexVYpuH5WLo2Meta?.alias || [],
    redirect: indexVYpuH5WLo2Meta?.redirect,
    component: () => import("/app/pages/admin/matching/index.vue").then(m => m.default || m)
  },
  {
    name: matchyMHdYDj7ZMMeta?.name ?? "admin-matching-match___en",
    path: matchyMHdYDj7ZMMeta?.path ?? "/admin/matching/match",
    meta: matchyMHdYDj7ZMMeta || {},
    alias: matchyMHdYDj7ZMMeta?.alias || [],
    redirect: matchyMHdYDj7ZMMeta?.redirect,
    component: () => import("/app/pages/admin/matching/match.vue").then(m => m.default || m)
  },
  {
    name: matchyMHdYDj7ZMMeta?.name ?? "admin-matching-match___de",
    path: matchyMHdYDj7ZMMeta?.path ?? "/de/admin/matching/match",
    meta: matchyMHdYDj7ZMMeta || {},
    alias: matchyMHdYDj7ZMMeta?.alias || [],
    redirect: matchyMHdYDj7ZMMeta?.redirect,
    component: () => import("/app/pages/admin/matching/match.vue").then(m => m.default || m)
  },
  {
    name: migrateXpOvjGHxCdMeta?.name ?? "admin-migrate___en",
    path: migrateXpOvjGHxCdMeta?.path ?? "/admin/migrate",
    meta: migrateXpOvjGHxCdMeta || {},
    alias: migrateXpOvjGHxCdMeta?.alias || [],
    redirect: migrateXpOvjGHxCdMeta?.redirect,
    component: () => import("/app/pages/admin/migrate.vue").then(m => m.default || m)
  },
  {
    name: migrateXpOvjGHxCdMeta?.name ?? "admin-migrate___de",
    path: migrateXpOvjGHxCdMeta?.path ?? "/de/admin/migrate",
    meta: migrateXpOvjGHxCdMeta || {},
    alias: migrateXpOvjGHxCdMeta?.alias || [],
    redirect: migrateXpOvjGHxCdMeta?.redirect,
    component: () => import("/app/pages/admin/migrate.vue").then(m => m.default || m)
  },
  {
    name: notAdminAccount9iW01cutCUMeta?.name ?? "admin-notAdminAccount___en",
    path: notAdminAccount9iW01cutCUMeta?.path ?? "/admin/notAdminAccount",
    meta: notAdminAccount9iW01cutCUMeta || {},
    alias: notAdminAccount9iW01cutCUMeta?.alias || [],
    redirect: notAdminAccount9iW01cutCUMeta?.redirect,
    component: () => import("/app/pages/admin/notAdminAccount.vue").then(m => m.default || m)
  },
  {
    name: notAdminAccount9iW01cutCUMeta?.name ?? "admin-notAdminAccount___de",
    path: notAdminAccount9iW01cutCUMeta?.path ?? "/de/admin/notAdminAccount",
    meta: notAdminAccount9iW01cutCUMeta || {},
    alias: notAdminAccount9iW01cutCUMeta?.alias || [],
    redirect: notAdminAccount9iW01cutCUMeta?.redirect,
    component: () => import("/app/pages/admin/notAdminAccount.vue").then(m => m.default || m)
  },
  {
    name: notApprovedAdminPZVXysECQPMeta?.name ?? "admin-notApprovedAdmin___en",
    path: notApprovedAdminPZVXysECQPMeta?.path ?? "/admin/notApprovedAdmin",
    meta: notApprovedAdminPZVXysECQPMeta || {},
    alias: notApprovedAdminPZVXysECQPMeta?.alias || [],
    redirect: notApprovedAdminPZVXysECQPMeta?.redirect,
    component: () => import("/app/pages/admin/notApprovedAdmin.vue").then(m => m.default || m)
  },
  {
    name: notApprovedAdminPZVXysECQPMeta?.name ?? "admin-notApprovedAdmin___de",
    path: notApprovedAdminPZVXysECQPMeta?.path ?? "/de/admin/notApprovedAdmin",
    meta: notApprovedAdminPZVXysECQPMeta || {},
    alias: notApprovedAdminPZVXysECQPMeta?.alias || [],
    redirect: notApprovedAdminPZVXysECQPMeta?.redirect,
    component: () => import("/app/pages/admin/notApprovedAdmin.vue").then(m => m.default || m)
  },
  {
    name: queueEmailGuestsiUURPyD5TVMeta?.name ?? "admin-queueEmailGuests___en",
    path: queueEmailGuestsiUURPyD5TVMeta?.path ?? "/admin/queueEmailGuests",
    meta: queueEmailGuestsiUURPyD5TVMeta || {},
    alias: queueEmailGuestsiUURPyD5TVMeta?.alias || [],
    redirect: queueEmailGuestsiUURPyD5TVMeta?.redirect,
    component: () => import("/app/pages/admin/queueEmailGuests.vue").then(m => m.default || m)
  },
  {
    name: queueEmailGuestsiUURPyD5TVMeta?.name ?? "admin-queueEmailGuests___de",
    path: queueEmailGuestsiUURPyD5TVMeta?.path ?? "/de/admin/queueEmailGuests",
    meta: queueEmailGuestsiUURPyD5TVMeta || {},
    alias: queueEmailGuestsiUURPyD5TVMeta?.alias || [],
    redirect: queueEmailGuestsiUURPyD5TVMeta?.redirect,
    component: () => import("/app/pages/admin/queueEmailGuests.vue").then(m => m.default || m)
  },
  {
    name: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.name ?? "admin-queueEmailHostLastYearNotRegisteredAgain___en",
    path: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.path ?? "/admin/queueEmailHostLastYearNotRegisteredAgain",
    meta: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta || {},
    alias: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.alias || [],
    redirect: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.redirect,
    component: () => import("/app/pages/admin/queueEmailHostLastYearNotRegisteredAgain.vue").then(m => m.default || m)
  },
  {
    name: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.name ?? "admin-queueEmailHostLastYearNotRegisteredAgain___de",
    path: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.path ?? "/de/admin/queueEmailHostLastYearNotRegisteredAgain",
    meta: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta || {},
    alias: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.alias || [],
    redirect: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta?.redirect,
    component: () => import("/app/pages/admin/queueEmailHostLastYearNotRegisteredAgain.vue").then(m => m.default || m)
  },
  {
    name: queueEmailMatchedGuestsSeZ5AFi054Meta?.name ?? "admin-queueEmailMatchedGuests___en",
    path: queueEmailMatchedGuestsSeZ5AFi054Meta?.path ?? "/admin/queueEmailMatchedGuests",
    meta: queueEmailMatchedGuestsSeZ5AFi054Meta || {},
    alias: queueEmailMatchedGuestsSeZ5AFi054Meta?.alias || [],
    redirect: queueEmailMatchedGuestsSeZ5AFi054Meta?.redirect,
    component: () => import("/app/pages/admin/queueEmailMatchedGuests.vue").then(m => m.default || m)
  },
  {
    name: queueEmailMatchedGuestsSeZ5AFi054Meta?.name ?? "admin-queueEmailMatchedGuests___de",
    path: queueEmailMatchedGuestsSeZ5AFi054Meta?.path ?? "/de/admin/queueEmailMatchedGuests",
    meta: queueEmailMatchedGuestsSeZ5AFi054Meta || {},
    alias: queueEmailMatchedGuestsSeZ5AFi054Meta?.alias || [],
    redirect: queueEmailMatchedGuestsSeZ5AFi054Meta?.redirect,
    component: () => import("/app/pages/admin/queueEmailMatchedGuests.vue").then(m => m.default || m)
  },
  {
    name: queueEmailMatchedHostsfSglX2Ci4lMeta?.name ?? "admin-queueEmailMatchedHosts___en",
    path: queueEmailMatchedHostsfSglX2Ci4lMeta?.path ?? "/admin/queueEmailMatchedHosts",
    meta: queueEmailMatchedHostsfSglX2Ci4lMeta || {},
    alias: queueEmailMatchedHostsfSglX2Ci4lMeta?.alias || [],
    redirect: queueEmailMatchedHostsfSglX2Ci4lMeta?.redirect,
    component: () => import("/app/pages/admin/queueEmailMatchedHosts.vue").then(m => m.default || m)
  },
  {
    name: queueEmailMatchedHostsfSglX2Ci4lMeta?.name ?? "admin-queueEmailMatchedHosts___de",
    path: queueEmailMatchedHostsfSglX2Ci4lMeta?.path ?? "/de/admin/queueEmailMatchedHosts",
    meta: queueEmailMatchedHostsfSglX2Ci4lMeta || {},
    alias: queueEmailMatchedHostsfSglX2Ci4lMeta?.alias || [],
    redirect: queueEmailMatchedHostsfSglX2Ci4lMeta?.redirect,
    component: () => import("/app/pages/admin/queueEmailMatchedHosts.vue").then(m => m.default || m)
  },
  {
    name: registerJ9n6lFFJhbMeta?.name ?? "admin-register___en",
    path: registerJ9n6lFFJhbMeta?.path ?? "/admin/register",
    meta: registerJ9n6lFFJhbMeta || {},
    alias: registerJ9n6lFFJhbMeta?.alias || [],
    redirect: registerJ9n6lFFJhbMeta?.redirect,
    component: () => import("/app/pages/admin/register.vue").then(m => m.default || m)
  },
  {
    name: registerJ9n6lFFJhbMeta?.name ?? "admin-register___de",
    path: registerJ9n6lFFJhbMeta?.path ?? "/de/admin/register",
    meta: registerJ9n6lFFJhbMeta || {},
    alias: registerJ9n6lFFJhbMeta?.alias || [],
    redirect: registerJ9n6lFFJhbMeta?.redirect,
    component: () => import("/app/pages/admin/register.vue").then(m => m.default || m)
  },
  {
    name: resendMatchEmailsKd4CMkJ0Q0Meta?.name ?? "admin-resendMatchEmails___en",
    path: resendMatchEmailsKd4CMkJ0Q0Meta?.path ?? "/admin/resendMatchEmails",
    meta: resendMatchEmailsKd4CMkJ0Q0Meta || {},
    alias: resendMatchEmailsKd4CMkJ0Q0Meta?.alias || [],
    redirect: resendMatchEmailsKd4CMkJ0Q0Meta?.redirect,
    component: () => import("/app/pages/admin/resendMatchEmails.vue").then(m => m.default || m)
  },
  {
    name: resendMatchEmailsKd4CMkJ0Q0Meta?.name ?? "admin-resendMatchEmails___de",
    path: resendMatchEmailsKd4CMkJ0Q0Meta?.path ?? "/de/admin/resendMatchEmails",
    meta: resendMatchEmailsKd4CMkJ0Q0Meta || {},
    alias: resendMatchEmailsKd4CMkJ0Q0Meta?.alias || [],
    redirect: resendMatchEmailsKd4CMkJ0Q0Meta?.redirect,
    component: () => import("/app/pages/admin/resendMatchEmails.vue").then(m => m.default || m)
  },
  {
    name: roomsWz58ChAzt5Meta?.name ?? "admin-rooms___en",
    path: roomsWz58ChAzt5Meta?.path ?? "/admin/rooms",
    meta: roomsWz58ChAzt5Meta || {},
    alias: roomsWz58ChAzt5Meta?.alias || [],
    redirect: roomsWz58ChAzt5Meta?.redirect,
    component: () => import("/app/pages/admin/rooms.vue").then(m => m.default || m)
  },
  {
    name: roomsWz58ChAzt5Meta?.name ?? "admin-rooms___de",
    path: roomsWz58ChAzt5Meta?.path ?? "/de/admin/rooms",
    meta: roomsWz58ChAzt5Meta || {},
    alias: roomsWz58ChAzt5Meta?.alias || [],
    redirect: roomsWz58ChAzt5Meta?.redirect,
    component: () => import("/app/pages/admin/rooms.vue").then(m => m.default || m)
  },
  {
    name: confirmNewsletterTrqX2OYym4Meta?.name ?? "confirmNewsletter___en",
    path: confirmNewsletterTrqX2OYym4Meta?.path ?? "/confirmNewsletter",
    meta: confirmNewsletterTrqX2OYym4Meta || {},
    alias: confirmNewsletterTrqX2OYym4Meta?.alias || [],
    redirect: confirmNewsletterTrqX2OYym4Meta?.redirect,
    component: () => import("/app/pages/confirmNewsletter.vue").then(m => m.default || m)
  },
  {
    name: confirmNewsletterTrqX2OYym4Meta?.name ?? "confirmNewsletter___de",
    path: confirmNewsletterTrqX2OYym4Meta?.path ?? "/de/confirmNewsletter",
    meta: confirmNewsletterTrqX2OYym4Meta || {},
    alias: confirmNewsletterTrqX2OYym4Meta?.alias || [],
    redirect: confirmNewsletterTrqX2OYym4Meta?.redirect,
    component: () => import("/app/pages/confirmNewsletter.vue").then(m => m.default || m)
  },
  {
    name: contactUssMQ6gX1U2qMeta?.name ?? "contactUs___en",
    path: contactUssMQ6gX1U2qMeta?.path ?? "/contactUs",
    meta: contactUssMQ6gX1U2qMeta || {},
    alias: contactUssMQ6gX1U2qMeta?.alias || [],
    redirect: contactUssMQ6gX1U2qMeta?.redirect,
    component: () => import("/app/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: contactUssMQ6gX1U2qMeta?.name ?? "contactUs___de",
    path: contactUssMQ6gX1U2qMeta?.path ?? "/de/contactUs",
    meta: contactUssMQ6gX1U2qMeta || {},
    alias: contactUssMQ6gX1U2qMeta?.alias || [],
    redirect: contactUssMQ6gX1U2qMeta?.redirect,
    component: () => import("/app/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: dsgvoze1kwAX27hMeta?.name ?? "dsgvo___en",
    path: dsgvoze1kwAX27hMeta?.path ?? "/dsgvo",
    meta: dsgvoze1kwAX27hMeta || {},
    alias: dsgvoze1kwAX27hMeta?.alias || [],
    redirect: dsgvoze1kwAX27hMeta?.redirect,
    component: () => import("/app/pages/dsgvo.vue").then(m => m.default || m)
  },
  {
    name: dsgvoze1kwAX27hMeta?.name ?? "dsgvo___de",
    path: dsgvoze1kwAX27hMeta?.path ?? "/de/dsgvo",
    meta: dsgvoze1kwAX27hMeta || {},
    alias: dsgvoze1kwAX27hMeta?.alias || [],
    redirect: dsgvoze1kwAX27hMeta?.redirect,
    component: () => import("/app/pages/dsgvo.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq___en",
    path: faqIMkmXQpKKaMeta?.path ?? "/faq",
    meta: faqIMkmXQpKKaMeta || {},
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq___de",
    path: faqIMkmXQpKKaMeta?.path ?? "/de/faq",
    meta: faqIMkmXQpKKaMeta || {},
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: HowItWorksIIHwyNaBEHMeta?.name ?? "HowItWorks___en",
    path: HowItWorksIIHwyNaBEHMeta?.path ?? "/HowItWorks",
    meta: HowItWorksIIHwyNaBEHMeta || {},
    alias: HowItWorksIIHwyNaBEHMeta?.alias || [],
    redirect: HowItWorksIIHwyNaBEHMeta?.redirect,
    component: () => import("/app/pages/HowItWorks.vue").then(m => m.default || m)
  },
  {
    name: HowItWorksIIHwyNaBEHMeta?.name ?? "HowItWorks___de",
    path: HowItWorksIIHwyNaBEHMeta?.path ?? "/de/HowItWorks",
    meta: HowItWorksIIHwyNaBEHMeta || {},
    alias: HowItWorksIIHwyNaBEHMeta?.alias || [],
    redirect: HowItWorksIIHwyNaBEHMeta?.redirect,
    component: () => import("/app/pages/HowItWorks.vue").then(m => m.default || m)
  },
  {
    name: impressumLDkFRkkSAmMeta?.name ?? "impressum___en",
    path: impressumLDkFRkkSAmMeta?.path ?? "/impressum",
    meta: impressumLDkFRkkSAmMeta || {},
    alias: impressumLDkFRkkSAmMeta?.alias || [],
    redirect: impressumLDkFRkkSAmMeta?.redirect,
    component: () => import("/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressumLDkFRkkSAmMeta?.name ?? "impressum___de",
    path: impressumLDkFRkkSAmMeta?.path ?? "/de/impressum",
    meta: impressumLDkFRkkSAmMeta || {},
    alias: impressumLDkFRkkSAmMeta?.alias || [],
    redirect: impressumLDkFRkkSAmMeta?.redirect,
    component: () => import("/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___de",
    path: indexN6pT4Un8hYMeta?.path ?? "/de",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___en",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___de",
    path: loginhHM0vSTW5jMeta?.path ?? "/de/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout___en",
    path: logoutHaVXWqg8UAMeta?.path ?? "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout___de",
    path: logoutHaVXWqg8UAMeta?.path ?? "/de/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: otp63Htgd7JyaMeta?.name ?? "otp___en",
    path: otp63Htgd7JyaMeta?.path ?? "/otp",
    meta: otp63Htgd7JyaMeta || {},
    alias: otp63Htgd7JyaMeta?.alias || [],
    redirect: otp63Htgd7JyaMeta?.redirect,
    component: () => import("/app/pages/otp.vue").then(m => m.default || m)
  },
  {
    name: otp63Htgd7JyaMeta?.name ?? "otp___de",
    path: otp63Htgd7JyaMeta?.path ?? "/de/otp",
    meta: otp63Htgd7JyaMeta || {},
    alias: otp63Htgd7JyaMeta?.alias || [],
    redirect: otp63Htgd7JyaMeta?.redirect,
    component: () => import("/app/pages/otp.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___en",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___de",
    path: profileupBlgdy3vLMeta?.path ?? "/de/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: resendConfirmationEmailZkezVO4IOHMeta?.name ?? "resendConfirmationEmail___en",
    path: resendConfirmationEmailZkezVO4IOHMeta?.path ?? "/resendConfirmationEmail",
    meta: resendConfirmationEmailZkezVO4IOHMeta || {},
    alias: resendConfirmationEmailZkezVO4IOHMeta?.alias || [],
    redirect: resendConfirmationEmailZkezVO4IOHMeta?.redirect,
    component: () => import("/app/pages/resendConfirmationEmail.vue").then(m => m.default || m)
  },
  {
    name: resendConfirmationEmailZkezVO4IOHMeta?.name ?? "resendConfirmationEmail___de",
    path: resendConfirmationEmailZkezVO4IOHMeta?.path ?? "/de/resendConfirmationEmail",
    meta: resendConfirmationEmailZkezVO4IOHMeta || {},
    alias: resendConfirmationEmailZkezVO4IOHMeta?.alias || [],
    redirect: resendConfirmationEmailZkezVO4IOHMeta?.redirect,
    component: () => import("/app/pages/resendConfirmationEmail.vue").then(m => m.default || m)
  },
  {
    name: confirmEmaildu4BEQuqJYMeta?.name ?? "signUp-guest-confirmEmail___en",
    path: confirmEmaildu4BEQuqJYMeta?.path ?? "/signUp/guest/confirmEmail",
    meta: confirmEmaildu4BEQuqJYMeta || {},
    alias: confirmEmaildu4BEQuqJYMeta?.alias || [],
    redirect: confirmEmaildu4BEQuqJYMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/confirmEmail.vue").then(m => m.default || m)
  },
  {
    name: confirmEmaildu4BEQuqJYMeta?.name ?? "signUp-guest-confirmEmail___de",
    path: confirmEmaildu4BEQuqJYMeta?.path ?? "/de/signUp/guest/confirmEmail",
    meta: confirmEmaildu4BEQuqJYMeta || {},
    alias: confirmEmaildu4BEQuqJYMeta?.alias || [],
    redirect: confirmEmaildu4BEQuqJYMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/confirmEmail.vue").then(m => m.default || m)
  },
  {
    name: formbrygspJltbMeta?.name ?? "signUp-guest-form___en",
    path: formbrygspJltbMeta?.path ?? "/signUp/guest/form",
    meta: formbrygspJltbMeta || {},
    alias: formbrygspJltbMeta?.alias || [],
    redirect: formbrygspJltbMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/form.vue").then(m => m.default || m)
  },
  {
    name: formbrygspJltbMeta?.name ?? "signUp-guest-form___de",
    path: formbrygspJltbMeta?.path ?? "/de/signUp/guest/form",
    meta: formbrygspJltbMeta || {},
    alias: formbrygspJltbMeta?.alias || [],
    redirect: formbrygspJltbMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/form.vue").then(m => m.default || m)
  },
  {
    name: getStartedhEJkV56DFFMeta?.name ?? "signUp-guest-getStarted___en",
    path: getStartedhEJkV56DFFMeta?.path ?? "/signUp/guest/getStarted",
    meta: getStartedhEJkV56DFFMeta || {},
    alias: getStartedhEJkV56DFFMeta?.alias || [],
    redirect: getStartedhEJkV56DFFMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/getStarted.vue").then(m => m.default || m)
  },
  {
    name: getStartedhEJkV56DFFMeta?.name ?? "signUp-guest-getStarted___de",
    path: getStartedhEJkV56DFFMeta?.path ?? "/de/signUp/guest/getStarted",
    meta: getStartedhEJkV56DFFMeta || {},
    alias: getStartedhEJkV56DFFMeta?.alias || [],
    redirect: getStartedhEJkV56DFFMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/getStarted.vue").then(m => m.default || m)
  },
  {
    name: indexffJDdXbRL3Meta?.name ?? "signUp-guest___en",
    path: indexffJDdXbRL3Meta?.path ?? "/signUp/guest",
    meta: indexffJDdXbRL3Meta || {},
    alias: indexffJDdXbRL3Meta?.alias || [],
    redirect: indexffJDdXbRL3Meta?.redirect,
    component: () => import("/app/pages/signUp/guest/index.vue").then(m => m.default || m)
  },
  {
    name: indexffJDdXbRL3Meta?.name ?? "signUp-guest___de",
    path: indexffJDdXbRL3Meta?.path ?? "/de/signUp/guest",
    meta: indexffJDdXbRL3Meta || {},
    alias: indexffJDdXbRL3Meta?.alias || [],
    redirect: indexffJDdXbRL3Meta?.redirect,
    component: () => import("/app/pages/signUp/guest/index.vue").then(m => m.default || m)
  },
  {
    name: successq1juMKpFngMeta?.name ?? "signUp-guest-success___en",
    path: successq1juMKpFngMeta?.path ?? "/signUp/guest/success",
    meta: successq1juMKpFngMeta || {},
    alias: successq1juMKpFngMeta?.alias || [],
    redirect: successq1juMKpFngMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/success.vue").then(m => m.default || m)
  },
  {
    name: successq1juMKpFngMeta?.name ?? "signUp-guest-success___de",
    path: successq1juMKpFngMeta?.path ?? "/de/signUp/guest/success",
    meta: successq1juMKpFngMeta || {},
    alias: successq1juMKpFngMeta?.alias || [],
    redirect: successq1juMKpFngMeta?.redirect,
    component: () => import("/app/pages/signUp/guest/success.vue").then(m => m.default || m)
  },
  {
    name: confirmEmailAJpaAgM21jMeta?.name ?? "signUp-host-confirmEmail___en",
    path: confirmEmailAJpaAgM21jMeta?.path ?? "/signUp/host/confirmEmail",
    meta: confirmEmailAJpaAgM21jMeta || {},
    alias: confirmEmailAJpaAgM21jMeta?.alias || [],
    redirect: confirmEmailAJpaAgM21jMeta?.redirect,
    component: () => import("/app/pages/signUp/host/confirmEmail.vue").then(m => m.default || m)
  },
  {
    name: confirmEmailAJpaAgM21jMeta?.name ?? "signUp-host-confirmEmail___de",
    path: confirmEmailAJpaAgM21jMeta?.path ?? "/de/signUp/host/confirmEmail",
    meta: confirmEmailAJpaAgM21jMeta || {},
    alias: confirmEmailAJpaAgM21jMeta?.alias || [],
    redirect: confirmEmailAJpaAgM21jMeta?.redirect,
    component: () => import("/app/pages/signUp/host/confirmEmail.vue").then(m => m.default || m)
  },
  {
    name: formQJAOclckxYMeta?.name ?? "signUp-host-form___en",
    path: formQJAOclckxYMeta?.path ?? "/signUp/host/form",
    meta: formQJAOclckxYMeta || {},
    alias: formQJAOclckxYMeta?.alias || [],
    redirect: formQJAOclckxYMeta?.redirect,
    component: () => import("/app/pages/signUp/host/form.vue").then(m => m.default || m)
  },
  {
    name: formQJAOclckxYMeta?.name ?? "signUp-host-form___de",
    path: formQJAOclckxYMeta?.path ?? "/de/signUp/host/form",
    meta: formQJAOclckxYMeta || {},
    alias: formQJAOclckxYMeta?.alias || [],
    redirect: formQJAOclckxYMeta?.redirect,
    component: () => import("/app/pages/signUp/host/form.vue").then(m => m.default || m)
  },
  {
    name: getStarted4XwZtGx5S1Meta?.name ?? "signUp-host-getStarted___en",
    path: getStarted4XwZtGx5S1Meta?.path ?? "/signUp/host/getStarted",
    meta: getStarted4XwZtGx5S1Meta || {},
    alias: getStarted4XwZtGx5S1Meta?.alias || [],
    redirect: getStarted4XwZtGx5S1Meta?.redirect,
    component: () => import("/app/pages/signUp/host/getStarted.vue").then(m => m.default || m)
  },
  {
    name: getStarted4XwZtGx5S1Meta?.name ?? "signUp-host-getStarted___de",
    path: getStarted4XwZtGx5S1Meta?.path ?? "/de/signUp/host/getStarted",
    meta: getStarted4XwZtGx5S1Meta || {},
    alias: getStarted4XwZtGx5S1Meta?.alias || [],
    redirect: getStarted4XwZtGx5S1Meta?.redirect,
    component: () => import("/app/pages/signUp/host/getStarted.vue").then(m => m.default || m)
  },
  {
    name: index7CM3ILHpOJMeta?.name ?? "signUp-host___en",
    path: index7CM3ILHpOJMeta?.path ?? "/signUp/host",
    meta: index7CM3ILHpOJMeta || {},
    alias: index7CM3ILHpOJMeta?.alias || [],
    redirect: index7CM3ILHpOJMeta?.redirect,
    component: () => import("/app/pages/signUp/host/index.vue").then(m => m.default || m)
  },
  {
    name: index7CM3ILHpOJMeta?.name ?? "signUp-host___de",
    path: index7CM3ILHpOJMeta?.path ?? "/de/signUp/host",
    meta: index7CM3ILHpOJMeta || {},
    alias: index7CM3ILHpOJMeta?.alias || [],
    redirect: index7CM3ILHpOJMeta?.redirect,
    component: () => import("/app/pages/signUp/host/index.vue").then(m => m.default || m)
  },
  {
    name: success62Dy1UKI0RMeta?.name ?? "signUp-host-success___en",
    path: success62Dy1UKI0RMeta?.path ?? "/signUp/host/success",
    meta: success62Dy1UKI0RMeta || {},
    alias: success62Dy1UKI0RMeta?.alias || [],
    redirect: success62Dy1UKI0RMeta?.redirect,
    component: () => import("/app/pages/signUp/host/success.vue").then(m => m.default || m)
  },
  {
    name: success62Dy1UKI0RMeta?.name ?? "signUp-host-success___de",
    path: success62Dy1UKI0RMeta?.path ?? "/de/signUp/host/success",
    meta: success62Dy1UKI0RMeta || {},
    alias: success62Dy1UKI0RMeta?.alias || [],
    redirect: success62Dy1UKI0RMeta?.redirect,
    component: () => import("/app/pages/signUp/host/success.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test___en",
    path: testL3AVDvhyXeMeta?.path ?? "/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test___de",
    path: testL3AVDvhyXeMeta?.path ?? "/de/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  }
]